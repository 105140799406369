import BaseEmitter from 'tiny-emitter'
import { ControllerEventsAPI } from '@wix/thunderbolt-symbols'
import { CONTROLLER_EVENTS } from './moduleNames'

export type IControllerEvents = {
	createScopedControllerEvents: (controllerId: string, repeaterId?: string, itemId?: string) => ControllerEventsAPI
}

const ControllerEvents = (): IControllerEvents => {
	// @ts-ignore
	const emitter = new BaseEmitter()

	return {
		createScopedControllerEvents: (controllerId: string, repeaterId?: string, itemId?: string): ControllerEventsAPI => {
			const controllerScopedId = repeaterId ? `${controllerId}_${repeaterId}_${itemId}` : controllerId
			const scopeEvent = (event: string) => `${controllerScopedId}_${event}`

			return {
				on(event: string, callback: Function, context: any) {
					const scopedEvent = scopeEvent(event)
					emitter.on(scopedEvent, callback, context)
					return () => emitter.off(scopedEvent, callback)
				},

				once(event: string, callback: Function, context: any) {
					const scopedEvent = scopeEvent(event)
					emitter.once(scopedEvent, callback, context)
					return () => emitter.off(scopedEvent, callback)
				},

				off(event: string, callback: Function) {
					emitter.off(scopeEvent(event), callback)
				},

				fireEvent(event: string, ...args: any) {
					emitter.emit(scopeEvent(event), ...args)
				},
			}
		},
	}
}

export default {
	factory: ControllerEvents,
	deps: [],
	name: CONTROLLER_EVENTS,
}
